import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { GetQuoteButton } from '../GetQuoteButton/GetQuoteButton';
import ImageThreemaIdQrCode from './threema-id.png';
import { BiLinkExternal } from 'react-icons/bi';
import { GiDiamonds } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box id={'contact'} bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'lg'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('contact.title')}</Heading>
          <Text>{t('contact.subline')}</Text>

          <VStack spacing={6} pt={6}>
            <GetQuoteButton />
            <Text>{t('contact.description')}</Text>
            <Icon as={GiDiamonds} color={'gray.500'} />
            <Text>
              {t('contact.threema1')} <strong>Threema</strong>
              .<br />
              {t('contact.threema2')}{' '}
              <Link
                color={'orange.400'}
                href="https://threema.id/FN3N5HFU"
                isExternal>
                FN3N5HFU{' '}
                <Icon as={BiLinkExternal} position={'relative'} top={'3px'} />
              </Link>
              .<br />
              {t('contact.threemaDescription')}
            </Text>
            <Button
              variant="link"
              onClick={onOpen}
              aria-label="Threema QR code">
              <Box bg={'white'} padding={2} borderRadius={'lg'}>
                <Image
                  src={ImageThreemaIdQrCode}
                  h="96px"
                  w="96px"
                  alt="Threem QR code"
                />
              </Box>
            </Button>
          </VStack>

          <Modal size={'xs'} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg={'white'} border={'0 none'}>
              <ModalBody
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                py={6}>
                <Image src={ImageThreemaIdQrCode} h="100%" w="100%" />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Container>
    </Box>
  );
};
