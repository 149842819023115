import {
  Box,
  Button,
  Container,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../Logo/Logo';

export const Footer = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={16}
        spacing={12}
        justify={'center'}
        align={'center'}>
        <Logo mb={10} />
        <Stack direction={['column', 'row']} spacing={6}>
          <Link href={'#top'}>{t('common.top')}</Link>
          <Link href={'#features'}>{t('common.features')}</Link>
          <Link href={'#delivery'}>{t('common.delivery')}</Link>
          <Link href={'#procedure'}>{t('common.procedure')}</Link>
          <Link href={'#testimonials'}>{t('common.testimonials')}</Link>
          <Link href={'#faq'}>{t('common.faq')}</Link>
          <Link href={'#contact'}>{t('common.getAQuote')}</Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container as={Stack} maxW={'6xl'} py={10} spacing={4}>
          <Text>
            &copy; {year} Alexander Schmidt
            <br />
            <Button
              variant="link"
              onClick={onOpen}
              fontSize={'xl'}
              fontWeight={'normal'}>
              {t('common.imprint')}
            </Button>
            <br />
            {t('common.rights')}
          </Text>

          <Modal size={'xl'} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent border={'0 none'}>
              <ModalHeader>{t('common.imprint')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody py={6}>
                <Heading fontSize={'xl'} mb={4}>
                  {t('imprint.title')}
                </Heading>
                <Text mb={6}>
                  {t('common.wordpressThemeDeveloper')}
                  <br />
                  <br />
                  Alexander Schmidt
                  <br />
                  {t('imprint.street')}
                  <br />
                  {t('imprint.location')}
                  <br />
                  {t('common.switzerland')}
                </Text>
                <Heading fontSize={'xl'} mb={4}>
                  {t('imprint.representedBy')}
                </Heading>
                <Text>
                  {t('imprint.name')}
                  <br />
                  {t('imprint.emailAddress')}
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Container>
      </Box>
    </Box>
  );
};
