import { Heading, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as WordpressIcon } from "./wordpress-icon.min.svg";

export const Logo = ({
  fontSize = { base: "3xl", sm: "4xl", md: "6xl" },
  fontWeight = 600,
  mb,
}: {
  fontSize?: any;
  fontWeight?: number;
  mb?: number;
}) => {
  return (
    <Heading
      fontWeight={fontWeight}
      fontSize={fontSize}
      mb={mb}
      lineHeight={"110%"}
    >
      <Icon
        width={["36px", "72px"]}
        height={["36px", "72px"]}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        mt={["-2", "-4"]}
      >
        <WordpressIcon />
      </Icon>{" "}
      WordPress{" "}
      <Text as={"span"} color={"orange.400"}>
        Themes{" "}
      </Text>
      Developer
    </Heading>
  );
};
