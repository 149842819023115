import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { BsWordpress } from 'react-icons/bs';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { GoPackage } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

interface ThemePackageProps {
  id: number;
  icon: IconType;
  title: string;
  text: string;
}

const PackageText = ({ children }: { children: ReactNode }) => {
  return (
    <Text color={useColorModeValue('gray.600', 'gray.400')}>{children}</Text>
  );
};

const PackageCard = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      color={useColorModeValue('gray.700', 'gray.300')}
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth={'1px'}
      rounded={'2xl'}
      shadow={'lg'}
      overflow={'hidden'}
      position={'relative'}>
      {children}
    </Box>
  );
};

export const ThemePackage = () => {
  const { t } = useTranslation();

  const themePackages: ThemePackageProps[] = [
    {
      id: 1,
      icon: GoPackage,
      title: t('themePackage.title1'),
      text: t('themePackage.text1'),
    },
    {
      id: 2,
      icon: BsWordpress,
      title: t('themePackage.title2'),
      text: t('themePackage.text2'),
    },
    {
      id: 3,
      icon: AiOutlineFilePdf,
      title: t('themePackage.title3'),
      text: t('themePackage.text3'),
    },
  ];

  return (
    <Box id={'theme-package'} bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'6xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('themePackage.title')}</Heading>
          <Text>{t('themePackage.subline')}</Text>
        </Stack>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          {themePackages.map((themePackage) => (
            <Flex
              direction={['column', 'row']}
              key={themePackage.id}
              align={'center'}
              alignItems={'stretch'}
              gap={[6, 12]}
              mb={[10, 0]}>
              <PackageCard>
                <VStack align={'start'} textAlign={'left'} p={[5, 10]}>
                  <Icon as={themePackage.icon} h={10} w={10} mb={4} />
                  <Heading as={'h3'} fontSize={'xl'}>
                    {themePackage.title}
                  </Heading>
                  <PackageText>{themePackage.text}</PackageText>
                </VStack>
              </PackageCard>
            </Flex>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
