import {
  Flex,
  Container,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Link,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GetQuoteButton } from '../GetQuoteButton/GetQuoteButton';
import { Logo } from '../Logo/Logo';
import { ReactComponent as DeveloperOnDesk } from './developer-on-desk.svg';

const DeveloperIllustration = (props: IconProps) => {
  return (
    <Icon
      width="100%"
      viewBox="0 0 828 584"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <DeveloperOnDesk />
    </Icon>
  );
};

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Container id={'hero'} maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}>
        <Logo />
        <Text maxW={'3xl'}>{t('hero.description')}</Text>
        <Stack spacing={6} direction={['column', 'row']}>
          <GetQuoteButton />
          <Link href="#features" textDecoration={'none !important'}>
            <Button rounded={'full'} px={6}>
              {t('hero.learnMore')}
            </Button>
          </Link>
        </Stack>
        <Flex w={'full'}>
          <DeveloperIllustration
            h={{ sm: '24rem', lg: '28rem' }}
            mt={{ base: 12, sm: 16 }}
          />
        </Flex>
      </Stack>
    </Container>
  );
};
