import { Box, createIcon } from '@chakra-ui/react';

const SwissFlagIcon = createIcon({
  displayName: 'SwissFlagIcon',
  viewBox: '0 0 32 32',
  path: [
    <path d="M0 0h32v32H0z" fill="red" />,
    <path d="M13 6h6v7h7v6h-7v7h-6v-7H6v-6h7z" fill="#fff" />,
  ],
});

export const MadeInSwitzerland = () => {
  return (
    <Box
      id={'madeinswitzerland'}
      fontSize="xs"
      position="absolute"
      top="3"
      left="50%"
      transform="translate(-50%, 0)"
      color={'gray.500'}>
      <SwissFlagIcon /> Made in Switzerland
    </Box>
  );
};
