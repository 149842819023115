import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const LanguageMenu = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  let displayLanguage = 'en';
  if (i18n.language === 'de-CH') {
    displayLanguage = 'de';
  }

  return (
    <Box position={'absolute'} left={2} top={2}>
      <Menu>
        <MenuButton>{displayLanguage}</MenuButton>
        <MenuList>
          <MenuItem onClick={() => changeLanguage('de-CH')}>de</MenuItem>
          <MenuItem onClick={() => changeLanguage('en-GB')}>en</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
