import { ChakraProvider, Box, Grid, extendTheme } from '@chakra-ui/react';

import { ColorModeSwitcher } from './components/ColorModeSwitcher/ColorModeSwitcher';
import { Hero } from './components/Hero/Hero';
import { Features } from './components/Features/Features';
import { Footer } from './components/Footer/Footer';
import { MadeInSwitzerland } from './components/MadeInSwitzerland/MadeInSwitzerland';
import { Testimonials } from './components/Testimonials/Testimonials';
import { Procedure } from './components/Procedure/Procedure';
import { Contact } from './components/Contact/Contact';
import { ThemePackage } from './components/ThemePackage/ThemePackage';
import { FAQ } from './components/FAQ/FAQ';
import { LanguageMenu } from './components/LanguageMenu/LanguageMenu';

export const theme = extendTheme({
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
    },
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh">
        <LanguageMenu />
        <MadeInSwitzerland />
        <ColorModeSwitcher />
        <Hero />
        <Features />
        <ThemePackage />
        <Procedure />
        <Testimonials />
        <FAQ />
        <Contact />
        <Footer />
      </Grid>
    </Box>
  </ChakraProvider>
);
