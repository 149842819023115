import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface QuestionProps {
  id: number;
  question: string;
  answer: string;
}

const AnswerContent = ({ children }: { children: ReactNode }) => {
  return (
    <AccordionPanel pb={4} color={useColorModeValue('gray.600', 'gray.400')}>
      {children}
    </AccordionPanel>
  );
};

export const FAQ = () => {
  const { t } = useTranslation();
  const questions: QuestionProps[] = [];
  const questionsAmount = 6;

  for (let i = 1; i <= questionsAmount; i++) {
    const item: QuestionProps = {
      id: i,
      question: t('faq.question' + i) as string,
      answer: t('faq.answer' + i) as string,
    };

    questions.push(item);
  }

  return (
    <Box id={'faq'}>
      <Container maxW={'4xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('faq.title')}</Heading>
          <Text>{t('faq.subline')}</Text>
        </Stack>

        <Container maxW={'6xl'}>
          <Accordion allowMultiple textAlign={'left'}>
            {questions.map((item: QuestionProps) => (
              <AccordionItem key={item.id}>
                <Heading>
                  <AccordionButton fontSize={'xl'}>
                    <Box flex="1" textAlign="left">
                      {item.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Heading>
                <AnswerContent>{item.answer}</AnswerContent>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Container>
    </Box>
  );
};
