import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { GoCheck } from 'react-icons/go';

interface FeatureProps {
  id: number;
  title: string;
  text: string;
}

const FeatureText = ({ children }: { children: ReactNode }) => {
  return (
    <Text color={useColorModeValue('gray.600', 'gray.400')}>{children}</Text>
  );
};

export const Features = () => {
  const { t } = useTranslation();
  const features: FeatureProps[] = [];
  const featuresAmount = 8;

  for (let i = 1; i <= featuresAmount; i++) {
    const item: FeatureProps = {
      id: i,
      title: t('features.title' + i),
      text: t('features.text' + i),
    };

    features.push(item);
  }

  return (
    <Box id={'features'}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading>{t('features.title')}</Heading>
          <Text>{t('features.subline')}</Text>
        </Stack>

        <Container maxW={'6xl'} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={'top'}>
                <Box color={'green.400'} px={2} mt={0.5}>
                  <Icon as={GoCheck} />
                </Box>
                <VStack align={'start'} textAlign={'left'}>
                  <Heading as={'h3'} fontSize={'xl'}>
                    {feature.title}
                  </Heading>
                  <FeatureText>{feature.text}</FeatureText>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Container>
    </Box>
  );
};
