import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ProcedureStepProps {
  id: number;
  title: string;
  text: string;
  image: string;
}

const StepText = ({ children }: { children: ReactNode }) => {
  return (
    <Text color={useColorModeValue('gray.600', 'gray.400')}>{children}</Text>
  );
};

const StepCard = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      color={useColorModeValue('gray.700', 'gray.300')}
      bg={useColorModeValue('gray.100', 'gray.700')}
      borderWidth={'1px'}
      rounded={'2xl'}
      shadow={'lg'}
      overflow={'hidden'}
      position={'relative'}>
      {children}
    </Box>
  );
};

export const Procedure = () => {
  const { t } = useTranslation();
  const procedureSteps: ProcedureStepProps[] = [];
  const procedureStepsAmount = 5;

  for (let i = 1; i <= procedureStepsAmount; i++) {
    const item: ProcedureStepProps = {
      id: i,
      title: t('procedure.titleStep' + i),
      text: t('procedure.textStep' + i),
      image: t('procedure.imageStep' + i),
    };

    procedureSteps.push(item);
  }

  return (
    <Box id={'procedure'}>
      <Container maxW={'4xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('procedure.title')}</Heading>
          <Text>{t('procedure.subline')}</Text>
        </Stack>

        <Container maxW={'6xl'}>
          <SimpleGrid spacing={10}>
            {procedureSteps.map((step) => (
              <Flex
                direction={['column', 'row']}
                key={step.id}
                align={'center'}
                gap={[6, 12]}
                mb={[10, 0]}>
                <StepCard>
                  <Flex alignItems={'stretch'} direction={['column', 'row']}>
                    <Box
                      bgImage={step.image}
                      bgPosition={'center center'}
                      bgRepeat={'no-repeat'}
                      bgSize={'cover'}
                      order={[null, step.id % 2 === 0 ? null : 2]}
                      h={['180px', 'auto']}
                      w={'100%'}
                      minW={['100%', '120px']}>
                      {' '}
                    </Box>

                    <VStack align={'start'} textAlign={'left'} p={[5, 10]}>
                      <Heading as={'h3'} fontSize={'xl'}>
                        {step.id}. {step.title}
                      </Heading>
                      <StepText>{step.text}</StepText>
                    </VStack>
                  </Flex>
                </StepCard>
              </Flex>
            ))}
          </SimpleGrid>
        </Container>
      </Container>
    </Box>
  );
};
