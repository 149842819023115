import { Button, Icon, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BiLinkExternal } from 'react-icons/bi';

export const GetQuoteButton = () => {
  const { t } = useTranslation();
  const MailSubject = t('mail.subject');
  const MailBody = t('mail.body');

  return (
    <Link
      href={
        'mailto:service@wordpressthemesdeveloper.com?subject=' +
        MailSubject +
        '&body=' +
        MailBody
      }
      textDecoration={'none !important'}>
      <Button
        rounded={'full'}
        px={6}
        colorScheme={'orange'}
        bg={'orange.400'}
        _hover={{ bg: 'orange.500' }}>
        {t('common.getAQuote')} <Icon as={BiLinkExternal} ml={1} />
      </Button>
    </Link>
  );
};
