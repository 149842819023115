import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import deCH from "./de.json";
import enGB from "./en.json";

const resources = {
  "de-CH": { translation: deCH },
  "en-GB": { translation: enGB },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ["en-GB", "de-CH"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged editorSaved",
    },
    resources,
  });

export default i18n;
