import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface TestimonialProps {
  id: number;
  title: string;
  text: string;
  name: string;
  job: string;
  image: string;
}

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar bg={'white'} src={src} name={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box minW={['100%', '33.33%']}>{children}</Box>;
};

export const Testimonials = () => {
  const { t } = useTranslation();
  const testimonials: TestimonialProps[] = [];
  const testimonialsAmount = 2;

  for (let i = 1; i <= testimonialsAmount; i++) {
    const item: TestimonialProps = {
      id: i,
      title: t('testimonials.title' + i),
      text: t('testimonials.text' + i),
      name: t('testimonials.name' + i),
      job: t('testimonials.job' + i),
      image: t('testimonials.image' + i),
    };

    testimonials.push(item);
  }

  return (
    <Box id={'testimonials'} bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('testimonials.title')}</Heading>
          <Text>{t('testimonials.subline')}</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          {testimonials.map((item: TestimonialProps) => (
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>{item.title}</TestimonialHeading>
                <TestimonialText>{item.text}</TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={item.image}
                name={item.name}
                title={item.job}
              />
            </Testimonial>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
